<template>
  <div class="g_page_box">
    <div class="g_main_content">
      <div class="empty_box">
        <img src="../../assets/img/empty.png" alt="" width="185">
        <p>敬请期待</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  computed: {},
  components: {},
  watch: {},
  created() {},
  methods: {

  },
};
</script>

<style scoped>
.empty_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 23px;
  color: #242938;

  & p {
    padding-top: 50px;
  }
}
</style>
